import { Checkbox, FormControl, FormControlLabel, InputLabel, Theme } from '@material-ui/core';
import { Button, DialogActions, DialogContent, IconButton, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { AxiosError } from 'axios';
import cx from 'classnames';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { FilePicker } from 'components/FilePicker';
import { Modal } from 'components/Modal';
import { VerifyEmail } from 'components/VerifyEmail';
import { AgentDTO } from 'dtos/agent';
import { ApplicationDTO } from 'dtos/application';
import { PiiCountries } from 'dtos/deep-scan';
import { useToasters } from 'hooks/useToasters';
import { ReactComponent as ConfirmIcon } from 'images/confirm.svg';
import { useGetAgencyUsers } from 'queries/useAgency';
import { useAgentSettings, useUploadDomainCsv } from 'queries/useAgent';
import { useCreateApplications } from 'queries/useApplications';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AssessmentType, ROUTES } from 'telivy-constants';
import { isAgentAGlobalAdmin, isSecurityAgency } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { ScanTypeCards } from './ScanTypeCards';

interface StyleProps {
  created: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  section: {
    '& + $section': {
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
  },

  overlay: ({ created }) => ({
    ...TYPOGRAPHY.TITLE_3,
    opacity: created ? 1 : 0,
    transition: 'opacity 0.5s',
    pointerEvents: created ? 'all' : 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.WHITE,
  }),

  buttonContainer: {
    justifyContent: 'space-between',
  },

  buttonRight: {
    marginRight: theme.spacing(1),
  },

  modalInnerContent: {
    // marginTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(50),
    },
  },

  filePicker: {
    marginBottom: theme.spacing(2),
  },

  select: {
    marginBottom: theme.spacing(1),
  },

  marginLeftAuto: {
    marginLeft: 'auto',
  },

  telivyColor: {
    color: COLORS.BLUE_1,
  },

  fullWidth: {
    width: '100%',
  },
}));

interface Form {
  organizationName: string;
  domain?: string;
  country?: string;
  scanType?: AssessmentType;
  notifyViaEmail?: boolean;
  skipDomain?: boolean;
  selectUser?: boolean;
  agencyId?: string;
  agentId?: string;
}

interface Props {
  agent: AgentDTO;
  onApplicationCreated: (application: ApplicationDTO) => void;
  onClose: () => void;
}

const FORM_ID = 'create-application-form';

export const CreateApplicationForm = ({ agent, onApplicationCreated, onClose }: Props) => {
  const [created, setCreated] = useState(false);
  const classes = useStyles({ created });
  const history = useHistory();

  const { data: agentSettings } = useAgentSettings();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSelectedScanType, setIsSelectedScanType] = useState<boolean>(
    !(isSecurityAgency(agent) && agent.deepScanEnabled),
  );
  const { showToaster, toasterErrorHandler } = useToasters();

  const isGlobalAdmin = isAgentAGlobalAdmin(agent);

  const {
    mutate: uploadDomainCsv,
    isLoading: isUploading,
    reset: resetUploadeDomainCsv,
  } = useUploadDomainCsv(agent?.id, {
    onSuccess: () => {
      showToaster('Successfully uploaded CSV');
      history.push(ROUTES.agent.DASHBOARD);
      onClose();
    },
    onError: (error: AxiosError | unknown) => {
      setSelectedFile(null);
      resetUploadeDomainCsv();
      toasterErrorHandler(error);
    },
  });

  const handleFileChange = useCallback(
    (file: File) => {
      setSelectedFile(file);
      uploadDomainCsv(file);
    },
    [uploadDomainCsv],
  );

  const { mutate, isLoading } = useCreateApplications({
    onSuccess: (application) => {
      setCreated(true);

      setTimeout(() => {
        onApplicationCreated(application);
      }, 1000);
    },
    onError: (error: AxiosError | unknown) => {
      toasterErrorHandler(
        error,
        `Something wrong happened when creating ${
          isSecurityAgency(agent) ? 'assessment' : 'application'
        }. Please try again`,
      );
    },
  });

  const { handleSubmit, control, watch, setValue } = useForm<Form>({
    defaultValues: {
      organizationName: '',
      notifyViaEmail: true,
      skipDomain: false,
      scanType: AssessmentType.EXTERNAL,
      selectUser: isGlobalAdmin,
      agencyId: agent.agencyId || undefined,
      agentId: agent.id || undefined,
      country: 'US',
    },
  });

  const scanType = watch('scanType');
  const skipDomain = watch('skipDomain');
  const agencyId = watch('agencyId');
  const agentId = watch('agentId');
  const selectUser = watch('selectUser');

  const allAccounts = useMemo(() => {
    return agent.agency ? [agent.agency, ...(agent.subAgencies || [])] : [];
  }, [agent]);

  const { data: agencyUsers, isLoading: isLoadingAgencyUsers } = useGetAgencyUsers(agencyId || '', {
    enabled: Boolean(agencyId),
  });

  const onSubmit = (data: Form) => {
    mutate({
      organizationName: data.organizationName,
      domain: data?.skipDomain ? 'nodomain.local' : data?.domain,
      country: data?.country,
      notifyViaEmail: data?.notifyViaEmail,
      isDeepScan: data?.scanType === AssessmentType.RISK_PLUS,
      isLightScan: data?.scanType === AssessmentType.RISK,
      agentId: selectUser ? data.agentId : agent.id,
    });
  };

  const modalTitle = useMemo(() => {
    if (isSecurityAgency(agent)) {
      if (isSelectedScanType) {
        if (scanType === AssessmentType.EXTERNAL) {
          return (
            <span>
              Creating <span className={classes.telivyColor}>External Assessment</span>
            </span>
          );
        } else if (scanType === AssessmentType.RISK) {
          return (
            <span>
              Creating <span className={classes.telivyColor}>Risk Assessment</span>
            </span>
          );
        } else {
          return (
            <span>
              Creating <span className={classes.telivyColor}>Risk Assessment Plus</span>
            </span>
          );
        }
      } else {
        return 'Create Assessment';
      }
    } else {
      return 'Create Application';
    }
  }, [agent, isSelectedScanType, scanType, classes]);

  return (
    <>
      <DialogContent key='content'>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
        <Typography variant='h3' className={classes.title}>
          {modalTitle}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
          {isSecurityAgency(agent) && agent.deepScanEnabled && !isSelectedScanType && (
            <>
              {isGlobalAdmin && (
                <section className={classes.section}>
                  <Typography variant='body1' className={classes.sectionTitle}>
                    Which account and user would you like to create assessment under?
                  </Typography>
                  {/*<FormControlLabel
                    label='Create assessment for other user'
                    control={
                      <Controller
                        name='selectUser'
                        control={control}
                        render={({ field }) => <Checkbox {...field} checked={field.value} color='primary' />}
                      />
                    }
                  />*/}

                  {selectUser && (
                    <>
                      <Controller
                        name='agencyId'
                        control={control}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel id='select-account-label'>Account</InputLabel>
                            <Select
                              labelId='select-account-label'
                              value={field.value}
                              onChange={(el) => {
                                const newAgencyId = el.target.value as string;
                                field.onChange(newAgencyId);
                                if (newAgencyId != agent.agencyId) {
                                  setValue('agentId', undefined);
                                } else {
                                  setValue('agentId', agent.id);
                                }
                              }}
                              label='Select account'
                            >
                              {allAccounts.map((agency) => (
                                <MenuItem key={agency.id} value={agency.id}>
                                  {agency.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />

                      {(agencyUsers || []).length > 0 && (
                        <Controller
                          name='agentId'
                          control={control}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel id='select-user-label'>User</InputLabel>
                              <Select
                                disabled={isLoadingAgencyUsers}
                                labelId='select-account-user'
                                value={field.value}
                                onChange={(el) => {
                                  field.onChange(el.target.value as string);
                                }}
                                label='Select user'
                              >
                                {(agencyUsers || []).map((user) => (
                                  <MenuItem key={user.id} value={user.id}>
                                    {user.firstName} {user.lastName} ({user.email})
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      )}
                      <br />
                      <br />
                    </>
                  )}
                </section>
              )}
              {(!isGlobalAdmin || (agencyId && agentId)) && (
                <section className={classes.section}>
                  <Typography variant='body1' className={classes.sectionTitle}>
                    What type of assessment would you like to create?
                  </Typography>

                  <Controller
                    name='scanType'
                    control={control}
                    render={({ field }) => (
                      <ScanTypeCards
                        onChooseClick={(value: AssessmentType) => {
                          field.onChange(value);
                          setIsSelectedScanType(true);
                        }}
                      />
                    )}
                  />
                </section>
              )}
            </>
          )}

          {isSelectedScanType && agent && !agent.isEmailVerified && <VerifyEmail />}

          {isSelectedScanType && agent && agent.isEmailVerified && (
            <section className={classes.section}>
              {isSecurityAgency(agent) && agent.deepScanEnabled && (
                <Typography variant='body1' className={classes.sectionTitle}>
                  Enter company details
                </Typography>
              )}
              <Controller
                name='organizationName'
                control={control}
                defaultValue=''
                render={({ field }) => <CustomTextFieldShrink {...field} label='Company Name' required />}
              />

              {isSecurityAgency(agent) && (
                <>
                  <Controller
                    name='domain'
                    control={control}
                    defaultValue={skipDomain ? 'nodomain.local' : ''}
                    render={({ field }) => (
                      <CustomTextFieldShrink
                        {...field}
                        label={skipDomain ? 'nodomain.local' : 'Domain'}
                        required={!skipDomain}
                        disabled={skipDomain}
                      />
                    )}
                  />
                  {scanType === AssessmentType.EXTERNAL && (
                    <FormControlLabel
                      label='Notify me via email when external scan completes'
                      control={
                        <Controller
                          name='notifyViaEmail'
                          control={control}
                          render={({ field }) => <Checkbox {...field} checked={field.value} color='primary' />}
                        />
                      }
                    />
                  )}
                  {scanType === AssessmentType.RISK && (
                    <FormControlLabel
                      label='Skip domain for external scan'
                      control={
                        <Controller
                          name='skipDomain'
                          control={control}
                          render={({ field }) => <Checkbox {...field} checked={field.value} color='primary' />}
                        />
                      }
                    />
                  )}
                  {scanType === AssessmentType.RISK && (
                    <Controller
                      name='country'
                      control={control}
                      defaultValue='US'
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id='select-pii-country'>Country *</InputLabel>
                          <Select {...field} labelId='select-pii-country' label='Country' value={field.value} required>
                            {Object.keys(PiiCountries).map((code) => (
                              <MenuItem key={code} value={code}>
                                {PiiCountries[code]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  )}
                </>
              )}
            </section>
          )}
        </form>
      </DialogContent>

      {isSelectedScanType && agent && agent.isEmailVerified && (
        <DialogActions key='actions' className={cx(isSecurityAgency(agent) && classes.buttonContainer)}>
          {isSecurityAgency(agent) && scanType === AssessmentType.EXTERNAL && (
            <Modal
              openButton={
                <Button color='primary' variant='outlined'>
                  OR Upload Multiple Domains
                </Button>
              }
              title='Upload Multiple Domains'
              padding={4}
              autoWidth
            >
              <div className={classes.modalInnerContent}>
                {agentSettings && (
                  <p>
                    Submit up to {agentSettings.IMPORT_CSV_DOMAINS_LIMIT || 50} domains for scanning using CSV file
                    upload
                  </p>
                )}
                <FilePicker
                  inputProps={{
                    accept: '.csv',
                  }}
                  onReset={() => {
                    resetUploadeDomainCsv();
                    setSelectedFile(null);
                  }}
                  isLoading={isUploading}
                  onChange={handleFileChange}
                  label='Upload CSV File'
                  selectedFile={selectedFile}
                  className={classes.filePicker}
                />
                <a
                  download='import-sample.csv'
                  href='data:text/csv;charset=utf-8;base64,ZG9tYWluLGNvbXBhbnlOYW1lDQp0ZWxpdnkuY29tLFRlbGl2eSBJbmMNCnNhbHVzd2FsbC5jb20sU2FsdXN3YWxs'
                  target='_blank'
                  rel='noreferrer'
                >
                  Download Sample CSV
                </a>
              </div>
            </Modal>
          )}
          <div className={classes.marginLeftAuto}>
            <Button onClick={() => setIsSelectedScanType(false)} className={classes.buttonRight}>
              Go back
            </Button>
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} form={FORM_ID}>
              {isLoading ? 'Creating...' : 'Create'}
            </Button>
          </div>
        </DialogActions>
      )}

      <div className={classes.overlay}>
        <ConfirmIcon />
        <div>Created!</div>
      </div>
    </>
  );
};
